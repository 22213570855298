<template>
  <div class="evaluation">
    <EvaluateHeader :name="evaluation.name + ' / 考评表管理'"> </EvaluateHeader>
    <div class="btn-box">
      <div class="tips">
        <span>!</span>
        支持通过Excel导入考评表，支持引用历史考评表。
        <el-button
          type="text"
          :disabled="evaluation.status > 0"
          @click="downFile()"
          >考评表导入模板下载</el-button
        >
      </div>
      <div class="btn-list">
        <el-button
          type="primary"
          :disabled="evaluation.status > 0"
          @click="showUpLoad"
          >上传考评表</el-button
        >
        <el-button
          type="primary"
          :disabled="evaluation.status > 0"
          @click="showQuote"
          >引用考评表</el-button
        >
      </div>
    </div>
    <div v-if="evaluationList.length == 0" class="evaluation-box">
      <no-data></no-data>
    </div>
    <!-- <div > -->
    <evaluateType
      v-for="(v, i) in evaluationList"
      ref="evaluateType"
      :key="i"
      :question="v"
      :type="true"
      :index="i + 1"
    >
      <el-button
        type="text"
        icon="el-icon-delete"
        :disabled="evaluation.status > 0"
        @click="delQuestion(v)"
      >
        删除
      </el-button>
    </evaluateType>
    <!-- </div> -->
    <!-- 上传考评表 -->
    <el-dialog title="上传考评表" :visible.sync="dialogVisible" width="456px">
      <div class="down-file">
        当前仅支持excel文件，<el-button type="text" @click="downFile()"
          >下载Excel 模版</el-button
        >
      </div>
      <el-upload
        ref="uploadDemo"
        class="upload-demo"
        drag
        :action="examImportURL"
        :headers="uploadHeaders"
        accept=".xlsx,.xls"
        :before-upload="handleImportBefore"
        :on-success="handleImportOnSuccess"
        :on-error="handleImportOnError"
        :on-progress="handleImportOnProgress"
        multiple
        :data="xlsData"
        :auto-upload="false"
        :file-list="fileList"
        :limit="1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">请将文件拖放到此处或点击上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="fullscreenLoading"
          @click="upLoadEvaluate()"
        >
          导 入
        </el-button>
      </span>
    </el-dialog>
    <!-- 引用考评表 -->
    <el-dialog
      title="引用考评表"
      :visible.sync="quoteDialogVisible"
      width="458px"
    >
      <div>
        <div class="quote-item">
          <span>入学年份：</span>
          <el-select
            v-model="searchItems.year"
            placeholder="入学年份"
            style="margin-right: 18px"
            @change="changeLevel"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span>学段：</span>
          <el-select
            v-model="searchItems.level"
            placeholder="全部学段"
            @change="changeLevel"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="quote-item">
          <span>搜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;索：</span>
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            @keyup.enter.native="changeLevel"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="changeLevel"
            >
            </i>
          </el-input>
        </div>
        <el-radio-group v-model="evaluationType" class="edit-scroll-style">
          <el-radio
            v-for="(v, i) in evaluationListData"
            :key="i"
            :label="v.id"
            >{{ v.name }}</el-radio
          >
          <!-- <el-radio :label="6">备选项</el-radio>
          <el-radio :label="9">备选项</el-radio> -->
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quoteDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EvaluateHeader from "./component/evaluate-header";
import evaluateType from "./component/evaluate-type";
import { getStore } from "@/core/util/store";
import { fileDownloadByUrl, getYear } from "@/core/util/util";
import {
  evaluationGetbyid,
  getQuestion,
  deleteEvaluationquestion,
  evaluationList,
  evaluationquestionQuote,
} from "@/core/api/evaluate/evaluate";
import { levelOptions } from "@/core/util/constdata";
export default {
  name: "Evaluation",
  components: { EvaluateHeader, evaluateType },
  data() {
    return {
      evaluationList: [],
      evaluationListData: [],
      // questionList: [],
      // 导入阅卷分工
      dialogVisible: false,
      btnLoading: false,
      fullscreenLoading: false,
      quoteDialogVisible: false,
      examImportURL: "/qa/evaluationquestion/import",
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      yearList: getYear(),
      levelOptions: levelOptions(),
      fileList: [],
      xlsData: {
        id: "",
      },
      searchItems: {
        level: "",
        year: "",
        keyword: "",
        classNum: "",
      },
      evaluation: {
        name: "",
      },
      evaluationType: "",
    };
  },
  created() {
    this.evaluationGetbyid();
    this.getQuestion();
  },
  methods: {
    submit() {
      if (!this.evaluationType) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要引用的考评表！",
        });
        return;
      }
      let data = { id: this.$route.query.id, againId: this.evaluationType };
      this.evaluationquestionQuote(data);
    },
    getEvaluationList() {
      this.evaluationListData = [];
      this.evaluationType = "";
      evaluationList(this.searchItems).then((res) => {
        // console.log(res);
        this.evaluationListData = res.data.data;
      });
    },
    // 引用考评表
    evaluationquestionQuote(data) {
      this.btnLoading = true;
      evaluationquestionQuote(data)
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "引用成功",
          });
          this.quoteDialogVisible = false;
          this.btnLoading = false;
          this.getQuestion();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    showQuote() {
      this.quoteDialogVisible = true;
    },
    changeLevel() {
      this.getEvaluationList();
    },
    delQuestion(item) {
      // console.log(this.evaluation);
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "正在删除试题 "),
          h("span", { style: "color: red" }, item.evaluationQuestionOrder),
          h("span", null, " 删除后不可恢复，是否继续?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.deleteEvaluationquestion(item.id);
      });
    },
    deleteEvaluationquestion(data) {
      deleteEvaluationquestion(data).then(() => {
        this.getQuestion();
        this.$message({
          showClose: true,
          type: "success",
          message: "删除成功",
        });
      });
    },
    // 获取单个考评详情
    evaluationGetbyid() {
      let data = { id: this.$route.query.id };
      evaluationGetbyid(data).then((res) => {
        this.evaluation = res.data.data;
      });
    },
    // 获取考评试题列表
    getQuestion() {
      let data = { id: this.$route.query.id };
      getQuestion(data).then((res) => {
        res.data.data.map((index) => {
          index.questionContent = index.questionContent
            .replace(/\n/g, "<br/>")
            .replace(/\r/g, "<br/>");
        });
        if (res.data.data) {
          this.evaluationList = res.data.data;
        } else {
          this.evaluationList = [];
        }
        // console.log(this.evaluationList);
      });
    },
    upLoadEvaluate() {
      this.$refs.uploadDemo.submit();
    },
    showUpLoad() {
      this.dialogVisible = true;
      this.fileList = [];
    },
    downFile() {
      let url =
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/template/%E7%BB%BC%E5%90%88%E7%B4%A0%E8%B4%A8%E6%B5%8B%E8%AF%84%E6%A8%A1%E6%9D%BF.xls";
      let name = this.evaluation.name + "考评表模板.xls";
      fileDownloadByUrl(url, name);
    },
    // 导入 start
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    handleImportOnError(err, file, fileList) {
      this.$message({
        showClose: true,
        type: "error",
        message: "服务器内部错误!",
      });
      this.fullscreenLoading = false;
      this.handleImportFileRemove(fileList);
    },
    handleImportBefore(file) {
      if (!(file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
        this.$message.error("只能上传 Excel 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      const _vm = this;
      _vm.xlsData.id = this.evaluation.id;
      return true;
    },
    handleImportOnProgress() {
      // this.dialogVisible = false;
      this.fullscreenLoading = true;
    },
    // file, fileList
    handleImportOnSuccess(response, file, fileList) {
      // console.log(response);
      if (response.code == 0) {
        this.$message({
          showClose: true,
          type: "success",
          message: "导入成功",
          dangerouslyUseHTMLString: true,
          duration: 3000,
        });
        this.getQuestion();
        this.dialogVisible = false;
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: response.msg,
          dangerouslyUseHTMLString: true,
          duration: 20000,
        });
      }
      this.fullscreenLoading = false;
      this.handleImportFileRemove(fileList);
      // this.getDataList();
    },
    // 导入 end
  },
};
</script>
<style scoped lang="scss">
.evaluation {
  ::v-deep .el-dialog__footer {
    padding-top: 24px;
  }
  .el-dialog {
    .el-radio-group {
      height: 50px;
    }
    .el-radio {
      padding-left: 1px;
    }
    .quote-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      span {
        display: block;
        width: 76px;
        flex-shrink: 0;
        text-align: right;
      }
    }
    .el-select {
      width: 140px;
    }
  }
  ::v-deep .el-upload {
    display: block;
    .el-upload-dragger {
      width: 100%;
    }
  }
  .down-file {
    margin-bottom: 24px;
    .el-button {
      line-height: 17px;
      border-bottom: 1px solid #2474ed;
    }
  }

  .evaluation-box {
    background: #ffffff;
    padding: 24px;
    margin-bottom: 60px;
  }
  .btn-box {
    margin: 18px 0;
    padding: 24px;
    background: #ffffff;
    .btn-list {
      text-align: center;
      margin-top: 18px;
    }
  }
  .tips {
    padding: 6px 13px;
    background: #f2f8ff;
    border: 1px dashed #2474ed;
    color: #777473;
    .el-button {
      line-height: 17px;
      border-bottom: 1px solid #2474ed;
    }
    span {
      color: #ffffff;
      margin-right: 4px;
      &:first-child {
        padding: 0px 6px;
        font-size: 12px;
        border-radius: 50%;
        background-color: #2474ed;
        color: #ffffff;
        letter-spacing: 0;
      }
    }
  }
}
</style>
